import React from 'react';
import ImageGridModal from '../../modals/ImageGridModal';
import ToggleModal from '../../controls/ToggleModal';
import { getCardSizeURL } from '../../../utilities/images';

import './index.scss';

const ImageGrid = props => {

	const margin = props.margin && props.margin !== 'none' ? ` margin-${props.margin}` : '';
	const isDynamicGallery = props.enable_dynamic_gallery && props.enable_dynamic_gallery === true;

	return (
		<div id={props.id} className={`image-grid${margin} ${isDynamicGallery ? 'dynamic-gallery' : 'basic-gallery'}`}>
			<div className="row">
				{props.images && props.images.map((item, index) => {
					if (item.image) {
						return isDynamicGallery ? (
							<DynamicGalleryItem key={index} image={item.image} />
						) : (
							<BasicGalleryItem key={index} image={item.image} />
						);
					}

					return null;
				})}
			</div>
		</div>
	)
}

function BasicGalleryItem (props) {
	const {
		image,
	} = props;

	return (
		<div className="image">
			<img src={image.sizes.medium} alt={image.alt} />
		</div>
	)
}

function DynamicGalleryItem (props) {
	const {
		image,
	} = props;

	return (
		<React.Fragment>
			<div className="image dynamic-image">
				<ToggleModal target={`image-${image.name}`}>
					<img src={getCardSizeURL(image)} alt={image.alt} />
				</ToggleModal>
			</div>
			<ImageGridModal modalSlug={`image-${image.name}`} image={image} />
		</React.Fragment>
	);
}

export default ImageGrid;
