import React from 'react';
import { connect } from 'react-redux';

import SocialMedia from '../../elements/SocialMedia';

import './index.scss';

const mapStateToProps = (state) => ({
  footerStaticContent: state.api.data['static-content'].footer,
  searchParams: state.browser.searchParams,
});

const Footer = (props) => {
  const date = new Date();
  const year = date.getFullYear();
  const data = props.footerStaticContent;

  const style = {
    display: props.searchParams.modal ? 'none' : '',
  };

  if (data) {
    return (
      <footer className="footer" style={style}>
        <span className="footer-copyright">&copy; {year} DropDrop</span>
        <div className="footer-social">
          {data.acf.socialMedia.map((item, index) => (
            <SocialMedia key={index} icon={item.icon} url={item.url} />
          ))}
        </div>
      </footer>
    );
  }
  return null;
};

export default connect(mapStateToProps)(Footer);
