///////////////////////////////////////////////////////
// Takes a modal component and
// a slug to match against a 'modal' URL param
///////////////////////////////////////////////////////

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'qs';

import canUseDom from '../../../utilities/canUseDom';

import './index.scss';

const mapStateToProps = state => ({
	searchParams: state.browser.searchParams
})

const modalWrap = (PassedComponent, modalSlug, modalStyle) => {

	class ModalWrap extends Component {

		constructor(props) {
			super(props);

			this.bindEsc = (event) => {
				if (event.keyCode === 27) {
					if (this.props.searchParams.modal) {
						const newParams = { ...this.props.searchParams };
						delete newParams.modal;
						this.props.history.push({
							search: queryString.stringify(newParams)
						})
					}
				}
			}

			this.slug = this.props.modalSlug ? this.props.modalSlug : modalSlug;

			this.state = {
				open: false,
				domElement: null
			}
		}

		closeModal = () => {
			this.setState({ open: false });
		}

		openModal = () => {
			this.setState({ open: true });
		}

		setOpenStatus = () => {
			if (this.props.searchParams.modal === this.slug && !this.state.open) {
				this.openModal();
			}
			else {
				this.closeModal();
			}
		}

		componentDidMount() {

			if (canUseDom) {
				document.addEventListener('keydown', this.bindEsc, false);
			}

			this.setState({
				domElement: document.querySelector(`#modal-${this.slug}`)
			}, () => {
				if (this.props.searchParams.modal === this.slug) {
					this.openModal();
				}
			})
		}

		componentWillUnmount() {
			if (canUseDom) {
				document.removeEventListener('keydown', this.bindEsc, false);
			}
		}

		componentDidUpdate(prevProps) {
			if (this.props.searchParams.modal !== prevProps.searchParams.modal) {
				this.setOpenStatus();
			}
		}

		render() {

			return (
				<div className={`modal${this.state.open ? ' open' : ''}${modalStyle ? ` style-${modalStyle}` : ''}`}>
					<PassedComponent id={ `modal-${this.slug}` } { ...this.props } isOpen={ this.state.open } />
				</div>
			)
		}
	}

	return withRouter(connect(mapStateToProps)(ModalWrap));
}

export default modalWrap;
