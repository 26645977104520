import React, { Component } from 'react';
import { connect } from 'react-redux';

import Video from '../../elements/Video';
import breakpoints from '../../../breakpoints';
import isVideoURL from '../../../utilities/isVideoURL';

import './index.scss';

const mapStateToProps = state => ({
	windowWidth: state.browser.windowWidth,
	backgroundVideo: state.theme.backgroundVideo,
	showDefaultBackgroundVideo: state.theme.showDefaultBackgroundVideo,
	defaultBackgroundVideo: state.api.data['static-content']['background-video'],
});

class BackgroundVideo extends Component {

	shouldComponentUpdate(prevProps) {
		if (prevProps.defaultBackgroundVideo !== this.props.defaultBackgroundVideo ||
			prevProps.backgroundVideo !== this.props.backgroundVideo ||
			prevProps.showDefaultBackgroundVideo !== this.props.showDefaultBackgroundVideo ||
		   (prevProps.windowWidth !== this.props.windowWidth && this.props.windowWidth < breakpoints.mobileWidth)) {
			return true;
		}

		return false;
	}

	render() {
		const {
			showDefaultBackgroundVideo,
			backgroundVideo,
			defaultBackgroundVideo
		} = this.props;

		return (
			<MediaContent
				windowWidth={this.props.windowWidth}
				backgroundVideo={showDefaultBackgroundVideo ? defaultBackgroundVideo : backgroundVideo}
			/>
		)
	}
}

function MediaContent (props) {
	const {
		windowWidth,
		backgroundVideo
	} = props;

	if (backgroundVideo && backgroundVideo.acf) {
		const isMobileDevice = windowWidth < breakpoints.mobileWidth;
		if (isMobileDevice) {
			return null;
		}

		// Wordpress File inputs don't enforce a video extension - if this is not a video, just use the file as the poster
		const isVideo = isVideoURL(backgroundVideo.acf.backgroundVideo.url);

		return (
			<Video
				className="background-video"
				htmlVideoAttributes={{ loop: true, muted: true, autoPlay: true, playsInline: true }}
				poster={isVideo ? backgroundVideo.acf.backgroundVideoPoster.url : backgroundVideo.acf.backgroundVideo.url}
				src={isVideo ? backgroundVideo.acf.backgroundVideo.url : null}
				type="video/mp4"
			/>
		);
	}

	return null;
}

export default connect(mapStateToProps)(BackgroundVideo);
