import React from 'react';
import { Link } from 'react-router-dom';

import isLocalPath from '../../../utilities/isLocalPath';
import formatPermalink from '../../../utilities/formatPermalink';

import './index.scss';

const Hyperlink = props => {

	const classes = props.className ? `hyperlink ${props.className}` : 'hyperlink';

	const hyperlinkProps = {
		className: classes,
		style: props.style,
		target: props.target
	}

	const Contents = () => {
		return (
			<React.Fragment>
				{props.label &&
					<span>{props.label}</span>}
				{props.children &&
					props.children}
			</React.Fragment>
		)
	}

	if (props.url) {
		if (isLocalPath(props.url)) {
			const permalink = formatPermalink(props.url);
			return (
				<Link to={permalink} {...hyperlinkProps}>
					<Contents />
				</Link>
			)
		} else {
			return (
				<a {...hyperlinkProps} href={props.url} target="_blank" rel="noopener noreferrer">
					<Contents />
				</a>
			)
		}
	}

	return (
		<div {...hyperlinkProps}>
			<Contents />
		</div>
	)
}

export default Hyperlink;
