import React from 'react';

import HtmlBlock from '../../utilities/HtmlBlock';
import Form from '../../forms/Form';
import Input from '../../forms/Input';
import Button from '../../controls/Button';
import Textarea from '../../forms/Textarea';
import HeaderSpacer from '../../layout/HeaderSpacer';
import PageHeader from '../../elements/PageHeader';

import './index.scss';

const API_ROOT = process.env.REACT_APP_API_URL;
const FORM_ID = process.env.REACT_APP_CONTACT_FORM_ID;

const Contact = props => {
	return (
		<article className={`${props.slug} template-contact`}>
			<HeaderSpacer />
			<PageHeader data={props.data} featuredImage={props.data['_embedded']} />
			<div className="template-inner-wrapper">
				<div className="row">
					<div className="form-outer-wrapper">
						<Form
							method="post"
							action={`${API_ROOT}/wp-json/contact-form-7/v1/contact-forms/${FORM_ID}/feedback`}
							ajax={true}
							redirect="/thank-you">
							<Input type="text" label="Your First Name" id="first-name" required className="half" />
							<Input type="text" label="Your Last Name" id="last-name" required className="half" />
							<Input type="email" label="Your Email Address" id="email" required className="half" />
							<Input type="text" label="Your Organization" id="company" required className="half" />
							<Textarea rows={6} label="Your Message" id="message" required />
							<Button type="submit" >
								Send Message
							</Button>
						</Form>
					</div>
					<HtmlBlock className="content" content={props.data.content.rendered} />
				</div>
			</div>
		</article>
	)
}

export default Contact;
