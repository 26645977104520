import React from 'react';

import FlexibleContent from '../../../utilities/FlexibleContent';
import ViewHeader from '../../../elements/ViewHeader';

import './index.scss';
import PageHeader from '../../../elements/PageHeader';

const Authenticated = props => {

	const data = props.data;

	return (
		<React.Fragment>
			<PageHeader data={props.data} featuredImage={props.featuredImage} />
			<FlexibleContent content={data.acf.blocks} />
		</React.Fragment>
	)
}

export default Authenticated;
