import React from 'react';
import { connect } from 'react-redux';

import PersonModal from './PersonModal';
import LoadContent from '../../utilities/LoadContent';
import ToggleModal from '../../controls/ToggleModal';
import Parallax from '../../elements/Parallax';

import './index.scss';

const mapStateToProps = state => ({
	people: state.api.data.people
})

const PeopleGrid = props => {

	const margin = props.margin && props.margin !== 'none' ? ` margin-${props.margin}` : '';

	return (
		<div className={`people-grid${margin}`}>
			<div className="row">
				{props.people && props.peopleList.map((item, index) => {
					const person = props.people[item.person.post_name];
					if (person) {
						return (
							<div key={index} className="grid-item">
								<Parallax speed={.5 * (index / 10)}>
									<ToggleModal target={`person-${person.slug}`}>
										<h4 className="person-title">{person.title.rendered}</h4>
									</ToggleModal>
									<p className="person-position">{person.acf.position}</p>
									<ToggleModal target={`person-${person.slug}`}>
										<div className={`person-image${person.acf.thumbnailHoverImage.url ? ' has-hover' : ''}`}>
											<div className={`image-thumbnail${!person.acf.thumbnailImage.url ? ' no-image' : ''}`} style={person.acf.thumbnailImage.url ? { backgroundImage: `url(${person.acf.thumbnailImage.url})` } : {}} />
											{person.acf.thumbnailHoverImage.url &&
												<div className="thumbnail-hover" style={{ backgroundImage: `url(${person.acf.thumbnailHoverImage.url})` }} />
											}
										</div>
									</ToggleModal>
								</Parallax>
								<PersonModal modalSlug={`person-${person.slug}`} data={person} />
							</div>
						)
					}
					return null;
				})}
			</div>
			<LoadContent type="people" />
		</div>
	)
}

export default connect(mapStateToProps)(PeopleGrid);