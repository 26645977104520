import React from 'react';

import './index.scss';

const CollaborationWith = (props) => {
  return (
    <div className="collaboration-with">
      <p>[Done In Collaboration With {props.name}]</p>
    </div>
  );
};

export default CollaborationWith;
