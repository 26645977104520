import React, { Component } from 'react';
import { connect } from 'react-redux';

import EmbeddedImage from '../../elements/EmbeddedImage';
import Parallax from '../../elements/Parallax';

import './index.scss';

const mapStateToProps = state => ({
	windowWidth: state.browser.windowWidth
});

class AdvancedBlockquote extends Component {

	constructor(props) {
		super(props);

		this.contentRef = React.createRef();
		this.mediaRef = React.createRef();

		this.state = { minHeight: 0 }
	}

	measureHeights = () => {
		const contentHeight = this.contentRef.current.clientHeight;
		const mediaHeight = this.mediaRef.current.clientHeight;
		const minHeight = contentHeight > mediaHeight ? contentHeight : mediaHeight;
		this.setState({ minHeight });
	}

	componentDidMount() {
		this.measureHeights();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.windowWidth !== this.props.windowWidth) {
			this.measureHeights();
		}
	}

	render() {

		const {
			id,
			quote,
			quotee,
			photo,
		} = this.props;


		return (
			<div id={id} className="advanced-blockquote right" style={{ minHeight: this.state.minHeight }}>
				<div className="advanced-blockquote-outter-wrapper">
					<div className="advanced-blockquote-content-wrapper" ref={this.contentRef}>
						<div className="advanced-blockquote-content">
							<Parallax speed={.3} className="advanced-blockquote-quote">
								<div className="advanced-blockquote-quote-container">
									<blockquote className="quote" dangerouslySetInnerHTML={{ __html: quote}}/>
								</div>
								<hr />
								<h3>{quotee}</h3>
							</Parallax>
						</div>
					</div>
					<div className={`advanced-blockquote-media-wrapper`} ref={this.mediaRef}>
						<Parallax speed={.5} className={`advanced-blockquote-image`}>
							<EmbeddedImage onLoad={this.measureHeights} image={photo} />
						</Parallax>
					</div>
				</div>
			</div>
		)
	}
}

export default connect(mapStateToProps)(AdvancedBlockquote);
