import React from 'react';

const Logo = props => {
	return (
		<svg className="graphic logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 42">
			<g transform="translate(-40.000000, -38.000000)">
				<path className="fill" d="M44.4420797,75.5664993 C37.6704628,68.7508439 38.9027068,57.7502152 46.358708,52.5111825 L50.4685369,58.5255742 C46.6009289,61.2437969 46.004625,66.9893141 49.6282504,70.4668169 C51.7483443,72.5016875 54.9615152,73.0921395 57.651489,71.9425001 C62.2484021,69.9781978 63.6797077,64.3863232 60.9994229,60.4630783 L52.60977,48.1823927 C50.3390588,44.8612123 51.1555635,40.3019586 54.4303908,38 L66.9307533,56.2950772 C72.0975463,63.857507 69.4410431,74.5955141 60.7413475,78.5643158 C55.2794853,81.056541 48.6928371,79.845266 44.4420797,75.5664993 Z" id="logo"></path>
			</g>
		</svg>
	)
}

export default Logo;