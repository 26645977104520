import React from 'react';
import { connect } from 'react-redux';

import FlexibleContent from '../../utilities/FlexibleContent';
import EmbeddedImage from '../../elements/EmbeddedImage';
import PostHeader from '../../elements/PostHeader';
import Video from '../../elements/Video';

import './index.scss';

const mapStateToProps = state => ({
	windowWidth: state.browser.windowWidth
})

const Post = props => {
	const header = <PostHeader date={props.data.date} categoriesData={props.data['_embedded']} title={props.data.title.rendered} acf={props.data.acf} />
	let imgSource;
	let videoSource;
	if (props.data.acf.header && props.data.acf.header.overrideFeaturedImage) {
		if (props.data.acf.header.mediaType === "image") {
			imgSource = props.data.acf.header.image;
		}
		if (props.data.acf.header.mediaType === "video") {
			videoSource = props.data.acf.header.videoSnippet;
		}
	} else {
		imgSource = props.data['_embedded'];
	}

	return (
		<article className={`${props.slug} template-post`}>
			<div className="post-header">
				{imgSource ? (
					<EmbeddedImage className="header-image" image={imgSource} size={props.windowWidth > 600 ? 'header' : 'card' } />
				) : (
					<Video 
						aspectRatio={videoSource.aspectRatio ? videoSource.aspectRatio : 56.25}
						src={videoSource.video.url ? videoSource.video.url : ''}
						poster={videoSource.poster ? videoSource.poster.url : ''}
						htmlVideoAttributes={{ autoPlay: true, loop: true, muted: true, playsInline: true }}
						type="video/mp4" />
				)}
			</div>

			{props.data.acf.headerLayout === 'inline' ? (
				<FlexibleContent content={props.data.acf.blocks} header={header} />
			) : (
				<React.Fragment>
					{header}
					<FlexibleContent content={props.data.acf.blocks} />
				</React.Fragment>
			)}
		</article>
	)
}

export default connect(mapStateToProps)(Post);
