import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import FilterForm from './FilterForm';
import LoadContent from '../../utilities/LoadContent';
import getOffsetTop from '../../../utilities/getOffsetTop';
import Paginator from '../../elements/Paginator';

import smoothScroll from '../../../utilities/smoothScroll';
import sanitizePostType from '../../../utilities/sanitizePostType';

const mapStateToProps = state => ({
	scrollPos: state.browser.scrollPos,
	windowHeight: state.browser.windowHeight,
	windowWidth: state.browser.windowWidth,
	searchParams: state.browser.searchParams,
	lists: state.api.lists,
	data: state.api.data
});

class TaxonomyFilter extends Component {

	constructor(props) {
		super(props);
		this.state = {
			formIsVisible: false,
			resultsYPosition: null,
			data: {
				slugs: [],
				headers: {
					'X-WP-Total': 0
				}
			}
		}

		this.formRef = React.createRef();
		this.resultsRef = React.createRef();
		this.perPage = this.props.perPage ? this.props.perPage : 10;
	}

	checkFormVisibility = () => {
		if (this.props.fadeInForm) {
			if (this.state.resultsYPosition) {
				const visibleThreshold = this.props.scrollPos + this.props.windowHeight - 200;
				this.setState({
					formIsVisible: visibleThreshold >= this.state.resultsYPosition
				})
			}
		} else {
			this.setState({ formIsVisible: true })
		}
	}

	setResultsYPosition = () => {
		if (this.resultsRef.current) {
			const resultsYPosition = getOffsetTop(this.resultsRef.current);
			this.setState({ resultsYPosition });
		}
	}

	handleLoaded = data => {
		this.setState({ data });
	}

	scrollTo = () => {
		smoothScroll.scrollTo('taxonomyFilterResults');
	}

	componentDidUpdate(prevProps) {
		if (prevProps.scrollPos !== this.props.scrollPos) {
			this.setResultsYPosition();
			this.checkFormVisibility();
		}

		if ((prevProps.windowWidth !== this.props.windowWidth) || (prevProps.windowHeight !== this.props.windowHeight)) {
			this.setResultsYPosition();
			this.checkFormVisibility();
		}
	}

	componentDidMount() {
		this.setResultsYPosition();
	}

	render() {

		const { searchParams, taxonomy, lists, ResultsComponent, data, postType } = this.props;
		const taxonomyItems = lists[taxonomy];
		const { slugs } = this.state.data;

		const filterParams = searchParams[taxonomy] ? { [taxonomy]: searchParams[taxonomy] } : {};

		if (taxonomyItems) {
			return (
				<React.Fragment>
					<FilterForm isVisible={this.state.formIsVisible} taxonomy={taxonomy} />
					{slugs.length > 0 &&
						<React.Fragment>
							<TransitionGroup className="transition-wrap">
								<CSSTransition timeout={{ enter: 500, exit: 250 }} classNames="transition" key={slugs.map(slug => slug.slug).join('-')} onEntering={this.scrollTo}>
									<section id="taxonomyFilterResults" className="taxonomy-filter-results" ref={this.resultsRef}>
										<div className="results-row">
											{slugs.map((post, index) => {
												const fullPost = data[sanitizePostType(postType)][post.slug];
												if (fullPost) {
													return (
														<ResultsComponent key={index} data={fullPost} index={index} />
													)
												}
												return null;
											})}
										</div>
									</section>
								</CSSTransition>
							</TransitionGroup>
							<Paginator
								current={searchParams.page}
								total={this.state.data.headers['X-WP-Total']}
								per={this.perPage} />
						</React.Fragment>
					}
					<LoadContent type={sanitizePostType(postType)} onLoad={this.handleLoaded} query={{
						...filterParams,
						per_page: this.perPage,
						page: searchParams.page,
						_envelope: true
					}} />
				</React.Fragment>
			)
		}

		return null;
	}
}

export default withRouter(connect(mapStateToProps)(TaxonomyFilter));
