import React from 'react';

import HtmlBlock from '../../utilities/HtmlBlock';
import Form from '../../forms/Form';
import Input from '../../forms/Input';
import RadioGroup from '../../forms/RadioGroup';
import Button from '../../controls/Button';
import Textarea from '../../forms/Textarea';
import HeaderSpacer from '../../layout/HeaderSpacer';
import PageHeader from '../../elements/PageHeader';

const API_ROOT = process.env.REACT_APP_API_URL;
const FORM_ID = process.env.REACT_APP_POST_SURVEY_FORM_ID;

const radioObject = {
	devoted: {
		options: [
			"Exceptional!",
			"Good.",
			"Average.",
			"Underwhelming.",
			"Awful!"
		],
		label: "How were we as DEVOTED COLLABORATORS?",
		id: 'devoted'
	},
	resourceful: {
		options: [
			"Above and beyond!",
			"Good.",
			"Average.",
			"Underwhelming.",
			"Not at all!"
		],
		label: "How RESOURCEFUL and INNOVATIVE were we?",
		id: "resourceful"
	},
	inspiring: {
		options: [
			"Awesome!",
			"Good.",
			"Average.",
			"Underwhelming.",
			"Dreadful!"
		],
		label: "How did we do in making the work INSPIRING and FUN?",
		id: "inspiring"
	},
	flexible: {
		options: [
			"Rocked!",
			"Good.",
			"Average.",
			"Underwhelming.",
			"Terrible!"
		],
		label: "How did we hold up in staying FLEXIBLE and ADAPTABLE?",
		id: "flexible"
	},
	thorough: {
		options: [
			"Blew me away!",
			"Good.",
			"Average.",
			"Underwhelming.",
			"Horrible!"
		],
		label: "How THOROUGH were we?",
		id: "thorough"
	},
	pricing: {
		options: [
			"Wow, way exceeded expectations!",
			"Perfect, exactly what we paid for.",
			"A little high for what we got.",
			"Disappointing and underwhelming."
		],
		label: "How did our pricing and deliverables measure up?",
		id: "pricing"
	},
	recommend: {
		options: [
			"Definitely!",
			"Probably Would.",
			"Maybe.",
			"Probably Not.",
			"Definitely Not."
		],
		label: "Would you recommend us to others?",
		id: "recommend"
	},
	future_consideration: {
		options: [
			"Definitely!",
			"Probably Would.",
			"Maybe.",
			"Probably Not.",
			"Definitely Not."
		],
		label: "Would you consider us again for a future project?",
		id: "future_consideration"
	},
	follow_up: {
		options: [
			"Not at this time",
			"Within 3 months",
			"Within 6 months",
			"Within 12 months"
		],
		label: "Do you have upcoming projects we can follow up on?",
		id: "follow_up"
	},
	review_us: {
		options: [
			"Already done!",
			"I will later.",
			"Not at this time."
		],
		label: "Would you be willing to review us on Google and Facebook?",
		id: "review_us"
	}
}


const PostJobSurvey = props => {
	return (
		<article className={`${props.slug}`}>
			<HeaderSpacer />
			<PageHeader data={props.data} featuredImage={props.data['_embedded']} />
			<div className="template-inner-wrapper">
				<div className="row">
					<div className="form-outer-wrapper">
						<Form
							method="post"
							action={`${API_ROOT}/wp-json/contact-form-7/v1/contact-forms/${FORM_ID}/feedback`}
							ajax={true}
							redirect="/thank-you">
							<Input type="text" label="Your First Name" id="first-name" required className="half" />
							<Input type="text" label="Your Last Name" id="last-name" required className="half" />
							<Input type="email" label="Your Email Address" id="email" required className="half" />
							<Input type="text" label="Your Organization" id="company" required className="half" />

							<RadioGroup { ...radioObject.devoted } required />
							<RadioGroup { ...radioObject.resourceful } required />
							<RadioGroup { ...radioObject.inspiring } required />
							<RadioGroup { ...radioObject.flexible } required />
							<RadioGroup { ...radioObject.thorough } required />
							<RadioGroup { ...radioObject.pricing } required />
							<RadioGroup { ...radioObject.recommend } required />
							<RadioGroup { ...radioObject.future_consideration } required />
							<RadioGroup { ...radioObject.follow_up } required />
							<RadioGroup { ...radioObject.review_us } required />

							<Textarea className="small" rows={3} label="Any other thoughts?" id="message" required />
							<Button type="submit" >
								Send Message
							</Button>
						</Form>
					</div>
					<HtmlBlock className="content" content={props.data.content.rendered} />
				</div>
			</div>
		</article>
	)
}

export default PostJobSurvey;
