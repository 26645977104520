import React, { Component } from 'react';
import { connect } from 'react-redux';

import ProjectModal from '../../../modals/ProjectModal';
import EmbeddedImage from '../../../elements/EmbeddedImage';
import ToggleModal from '../../../controls/ToggleModal';
import Parallax from '../../../elements/Parallax';
import Video from '../../../elements/Video';

import stylizeNumber from '../../../../utilities/stylizeNumber';

import './index.scss';

const mapStateToProps = state => ({
	windowWidth: state.browser.windowWidth
});

class ProjectCard extends Component {

	constructor(props) {
		super(props);

		this.contentRef = React.createRef();
		this.mediaRef = React.createRef();

		this.state = { minHeight: 0 }
	}

	measureHeights = () => {
		const contentHeight = this.contentRef.current.clientHeight;
		const mediaHeight = this.mediaRef.current.clientHeight;
		const minHeight = contentHeight > mediaHeight ? contentHeight : mediaHeight;
		this.setState({ minHeight });
	}

	componentDidMount() {
		this.measureHeights();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.windowWidth !== this.props.windowWidth) {
			this.measureHeights();
		}
	}

	render() {

		const data = this.props.data;

		if (data) {

			const date = new Date(data.date);

			return (
				<div id={this.props.id} className={this.props.className} style={{ minHeight: this.state.minHeight }}>
					<div className="card-outter-wrapper">
						<div className="card-content-wrapper" ref={this.contentRef}>
							<div className="card-content">
								<Parallax speed={.3} className="card-title">
									<ToggleModal target={`project-${data.slug}`}>
										<h2>{data.title.rendered}</h2>
									</ToggleModal>
								</Parallax>
								<hr />
								<p className="card-excerpt">{data.acf.metaDescription}</p>
								<ToggleModal target={`project-${data.slug}`} className="modal-controller" buttonProps={{ arrow: true, color: "gray" }} />
							</div>
							<aside>
								<Parallax speed={.8} className="card-index">
									<span>{stylizeNumber(this.props.index + 1)}</span>
								</Parallax>
								<span className="card-client" >{data.acf.clientName && `${data.acf.clientName} - `}{date.getFullYear()}</span>
							</aside>
						</div>
						<div className="card-media-wrapper" ref={this.mediaRef}>
							<Parallax speed={.6} className="card-video">
								<Video
									aspectRatio={data.acf.videoSnippet.aspectRatio ? data.acf.videoSnippet.aspectRatio : 56.25}
									src={data.acf.videoSnippet.video ? data.acf.videoSnippet.video.url : ''}
									poster={data.acf.videoSnippet.poster ? data.acf.videoSnippet.poster.url : ''}
									htmlVideoAttributes={{ autoPlay: true, loop: true, muted: true, playsInline: true }}
									type="video/mp4" />
							</Parallax>
							<Parallax speed={.5} className="card-image">
								<EmbeddedImage onLoad={this.measureHeights} size="card" image={data['_embedded']} />
							</Parallax>
						</div>
					</div>
					<ProjectModal modalSlug={`project-${data.slug}`} data={data} />
				</div>
			)
		}
		return null;
	}
}

export default connect(mapStateToProps)(ProjectCard);
