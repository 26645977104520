import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import MobileMenuModal from './MobileMenuModal';
import ToggleModal from '../../controls/ToggleModal';

import Logo from '../../graphics/Logo';

import './index.scss';

const mapStateToProps = state => ({
	mainMenu: state.api.menus.main,
	windowWidth: state.browser.windowWidth
})

const mapDispatchToProps = dispatch => ({
	setHeaderHeight: height => dispatch({ type: 'SET_HEADER_HEIGHT', payload: height })
})

class Header extends Component {
	constructor(props) {
		super(props);
		this.headerRef = React.createRef();
	}

	setHeaderHeight = () => {
		const headerHeight = this.headerRef.current.clientHeight;
		this.props.setHeaderHeight(headerHeight);
	}

	componentDidMount() {
		this.setHeaderHeight();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.windowWidth !== this.props.windowWidth) {
			this.setHeaderHeight();
		}
	}

	render() {
		return (
			<header className="header-main" ref={this.headerRef}>
				<nav className="header-inner-wrapper">
					<Link to="/" className="header-logo">
						<Logo />
					</Link>
					<div className="header-menu-items">
						{ this.props.mainMenu && this.props.mainMenu.items.map(item => <Link key={ item.ID } to={ item.url }>{ item.title }</Link>) }
					</div>
					<ToggleModal target="mobile-menu" className="mobile-modal-toggler" hamburger={ true } />
					{ this.props.mainMenu && <MobileMenuModal modalSlug="mobile-menu" menuItems={ this.props.mainMenu.items } /> }
				</nav>
			</header>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
