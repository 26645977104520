import { Component } from 'react';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';

const mapStateToProps = state => ({
	scrollPos: state.browser.scrollPos
})

const mapDispatchToProps = dispatch => ({
	updateScroll: (pos) => dispatch({ type: 'UPDATE_SCROLL', payload: pos })
})

class MeasureScroll extends Component {

	onScroll = () => {
		this.props.updateScroll(window.pageYOffset);
	}

	componentDidMount() {
		if ('scrollRestoration' in window.history) {
			window.history.scrollRestoration = 'manual';
		}

		window.addEventListener('scroll', throttle(this.onScroll, 40));
	}

	render() {
		return null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MeasureScroll);
