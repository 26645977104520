import React from 'react';
import { withRouter } from 'react-router-dom';

import modalWrap from '../../../modals/modalWrap';
import HtmlBlock from '../../../utilities/HtmlBlock';
import EmbeddedImage from '../../../elements/EmbeddedImage';
import CloseModal from '../../../controls/CloseModal';
import HeaderSpacer from '../../../layout/HeaderSpacer';

import './index.scss';

const PersonModal = props => {

	const data = props.data;

	if (data) {
		return (
			<div id={ props.id } className="person-modal">
				<HeaderSpacer />
				<div className="row">
					<div className="modal-image">
						<EmbeddedImage image={data['_embedded']} />
						<CloseModal />
					</div>
					<div className="modal-content">
						<h3>{data.title.rendered}</h3>
						<HtmlBlock content={data.acf.biography} />
					</div>
				</div>
			</div>
		)
	}
	return null;
}

export default modalWrap(withRouter(PersonModal), null);
