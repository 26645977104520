import React from 'react';

import * as blocks from '../../blocks';

import './index.scss';

export default props => {

	if (props.content) {

		return (
			<div className="flexible-content" id={props.id} style={props.style}>

				{props.content.map((block, i) => {
					const name = block['acf_fc_layout'];
					const Block = blocks[name];

					if (Block) {
						if (i === 0 && props.header) {
							return (
								<div className="flex-content-inline-header" key={i}>
									{props.header}
									<Block {...block} id={`${name}-${i}`} />
								</div>
							)
						}

						return <Block {...block} id={`${name}-${i}`} key={i} />;
					}

					return null;
				})}

			</div>
		)
	}

	return null;
}
