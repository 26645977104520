import React, { Component } from 'react';
import { connect } from 'react-redux';

import getOffsetTop from '../../../utilities/getOffsetTop';

const mapStateToProps = state => ({
	scrollPos: state.browser.scrollPos,
	windowHeight: state.browser.windowHeight
})

class Parallax extends Component {

	constructor(props) {
		super(props);

		this.state = {
			offsetTop: 0,
			style: {}
		}

		this.wrapRef = React.createRef();
	}

	setStyles() {
		if (this.wrapRef.current) {
			const offsetTop = getOffsetTop(this.wrapRef.current);
			const min = offsetTop;
			const max = offsetTop + this.props.windowHeight;
			const pos = (((this.props.scrollPos - min) / (max - min)) * 100).toFixed(3);
			const capped = Math.min(Math.max(pos, -300), 300);
			const transform = capped * this.props.speed * -1;
			const style = {
				willChange: 'transform',
				transform: `translate3d(0, ${transform}%, 0)`,
				transition: this.state.offsetTop !== 0 ? 'all 700ms cubic-bezier(0, 0, 0.2, 1)' : undefined
			};
			this.setState({ offsetTop, style })
		}
	}

	componentDidMount() {
		this.setStyles();
	}

	componentDidUpdate(prevProps) {
		if (this.props.scrollPos !== prevProps.scrollPos) {
			this.setStyles();
		}
	}

	render() {

		const className = this.props.className ? `${this.props.className} parallax` : `parallax`;

		return (
			<div id={this.props.id} ref={this.wrapRef} className={className} style={this.state.style}>
				{this.props.children}
			</div>
		)
	}
}

export default connect(mapStateToProps)(Parallax);
