import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'qs';

import Button from '../Button';
import Hamburger from '../../graphics/Hamburger';

import './index.scss';

const mapState = state => ({
	searchParams: state.browser.searchParams
})

const mapDispatch = dispatch => ({
	setParams: params => dispatch({ type: 'SET_SEARCH_PARAMS', payload: params })
})

const handleClick = props => {
	const newParams = { ...props.searchParams };

	if (props.searchParams.modal === props.target) {
		delete newParams.modal;
	} else {
		newParams.modal = props.target;
	}

	props.history.push({
		search: queryString.stringify(newParams)
	})
}

const ToggleModal = props => {
	const classes = props.className ? `toggle-modal ${props.className}` : 'toggle-modal';

	if (props.buttonProps) {
		return (
			<Button
				className={classes}
				onClick={() => handleClick(props)} {...props.buttonProps}
				onMouseEnter={props.onMouseEnter}
				onMouseLeave={props.onMouseLeave}
			>
				{props.children}
				{props.hamburger &&
					<Hamburger className={props.searchParams.modal === props.target ? 'active' : ''} />
				}
			</Button>
		)
	}

	return (
		<button
			className={classes}
			onClick={() => handleClick(props)}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
		>
			{props.children}
			{props.hamburger &&
				<Hamburger className={props.searchParams.modal === props.target ? 'active' : ''} />
			}
		</button>
	)
}

export default withRouter(connect(mapState, mapDispatch)(ToggleModal));
