import React from 'react';
import { connect } from 'react-redux';

import ViewHeader from '../../elements/ViewHeader';
import FlexibleContent from '../../utilities/FlexibleContent';
import HeaderSpacer from '../../layout/HeaderSpacer';

const mapStateToProps = state => ({
	notFound: state.api.data['static-content']['not-found']
})

const NotFound = props => {

	const data = props.notFound;

	return (
		<article className={ `${props.slug} template-not-found` }>
			<HeaderSpacer />
			{data &&
				<React.Fragment>
					<ViewHeader data={data.acf.viewHeader} featuredImage={data['_embedded']} />
					<FlexibleContent content={data.acf.blocks} />
				</React.Fragment>
			}
			{!data &&
				<ViewHeader type="basic" data={{ title: '404 - Not Found', content: 'The page you were looking for was not found' }} />
			}
		</article>
	)
}

export default connect(mapStateToProps)(NotFound);