import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Arrow from '../../graphics/Arrow';

import isLocalPath from '../../../utilities/isLocalPath';
import formatPermalink from '../../../utilities/formatPermalink';

import './index.scss';

class Button extends Component {
	render() {
		const type = this.props.type ? ` type-${this.props.type}` : ' type-primary';
		const color = this.props.color ? ` color-${this.props.color}` : '';
		const arrow = this.props.arrow ? ' arrow' : '';

		const baseClasses = `btn${type}${color}${arrow}`
		const classes = this.props.className ? `${baseClasses} ${this.props.className}` : baseClasses;

		const buttonProps = {
			className: classes,
			style: this.props.style,
			onClick: this.props.onClick,
			target: this.props.target,
			rel: this.props.rel
		}

		const Contents = () => {
			return (
				<React.Fragment>
					{this.props.label && <span className="btn-label">{this.props.label}</span>}
					{this.props.children && this.props.children}
					{(this.props.arrow || this.props.type === 'text') && <Arrow />}
				</React.Fragment>
			)
		}

		if (this.props.url) {
			if (isLocalPath(this.props.url) && !this.props.forceLocalReload) {
				const formattedUrl = formatPermalink(this.props.url);
				return (
					<Link {...buttonProps} to={formattedUrl}>
						<Contents />
					</Link>
				)

			} else {
				return (
					<a {...buttonProps} href={this.props.url} target={!this.props.forceLocalReload ? '_blank' : ''} rel="noopener noreferrer">
						<Contents />
					</a>
				)
			}
		}

		return (
			<button {...buttonProps}>
				<Contents />
			</button>
		)
	}
}

export default Button;
