import React, { Component } from 'react';
import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import Masonry from 'react-masonry-component';

import SocialMedia from '../../elements/SocialMedia';
import limitStringWithEllipsis from '../../../utilities/limitStringWithEllipsis';

import './index.scss';

const superagent = superagentPromise(_superagent, global.Promise);

class InstagramFeed extends Component {

	constructor(props) {
		super(props);

		this.state = {
			instagramPosts: []
		}

		this.fetchData();
	}

	fetchData = () => {
		superagent
			.get(`https://api.instagram.com/v1/users/self/media/recent/?access_token=${process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN}`).then(
				res => {
					this.setState({ instagramPosts: res.body.data })
				},
				error => console.error(error)
			)
	}

	render() {

		const margin = this.props.margin && this.props.margin !== 'none' ? ` margin-${this.props.margin}` : '';

		return (
			<div id={ this.props.id } className={ `instagram-feed${margin}` }>
				<div className="masonry-wrap">
					<Masonry className="masonry-list" elementType={ 'ul' }>
						{ this.state.instagramPosts.length > 0 && this.state.instagramPosts.map((item, index) => {
							return (
								<li key={ index } className="masonry-item">
									<a href={item.link} target="_blank" rel="noopener noreferrer">
										<img src={ item.images.standard_resolution.url } alt={ item.caption.text } />
										<div className="item-content">
											<SocialMedia icon="instagram" />
											<p>{ limitStringWithEllipsis(item.caption.text, 100) }</p>
										</div>
									</a>
								</li>
							)
						}) }
					</Masonry>
				</div>
			</div>
		)
	}
}

export default InstagramFeed;