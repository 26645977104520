import React from 'react';

import Card from '../../elements/Card';
import TaxonomyFilter from '../../elements/TaxonomyFilter';
import HeaderSpacer from '../../layout/HeaderSpacer';

import './index.scss';

const Blog = props => {
	return (
		<article className={ `${props.slug} template-blog` }>
			<HeaderSpacer />
			<h1 className="template-title">{props.data.acf.alternateHeaderTitle ? props.data.acf.alternateHeaderTitle : props.data.title.rendered}</h1>
			<TaxonomyFilter
			postType="post"
			taxonomy="categories"
			fadeIn={true}
			perPage={6}
			ResultsComponent={resultsProps => <Card {...resultsProps} type="post" />} />
		</article>
	)
}

export default Blog;
