import React from 'react';
import { Helmet } from 'react-helmet';
import getFeaturedImage from '../../../utilities/getFeaturedImage';

const Head = (props) => {
  const { data } = props;

  if (data) {
    let type = '';

    if (data.type && data.type !== 'page') {
      type = ` - ${data.type.charAt(0).toUpperCase() + data.type.slice(1)}`;
    }

    const image = data.acf.metaImage
      ? data.acf.metaImage.url
      : getFeaturedImage(data._embedded)
      ? getFeaturedImage(data._embedded).source_url
      : 'https://dropdrop.com/images/dropdrop-og.jpg';

    const title = data.acf.metaTitle
      ? data.acf.metaTitle
      : data.type
      ? `${data.title.rendered}${type}`
      : data.taxonomy
      ? data.name
      : '';

    const description = data.acf.metaDescription
      ? data.acf.metaDescription
      : data.acf.alternateHeaderTitle
      ? data.acf.alternateHeaderTitle
      : '';

    const url = data.link ? data.link : 'https://dropdrop.com';

    return (
      <Helmet encodeSpecialCharacters={true}>
        <title>{title} | DropDrop</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={data.acf.metaKeywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content="DropDrop" />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json">
          {`
						"@context": "http://schema.org",
						"@type": "Article",
						"image": "${image}",
						"headline": "${title}",
						"about": "${description}",
						"url": "${url}",
						${
              (data.type === 'projects' ||
                data.type === 'markets' ||
                data.type === 'properties') &&
              data.acf.contacts &&
              `"contactPoint": [{
								"@type": "ContactPoint",
								"telephone": ${data.acf.contacts[0].acf.phone},
								"contactType": "customer service"
							}]`
            },
						"publisher": {
							"@type": "Organization",
							"name": "DropDrop",
							"logo": {
								"@type": "ImageObject",
								"url": "/images/logo.png"
							}
						},
						"mainEntityOfPage": ""
					`}
        </script>
      </Helmet>
    );
  }
};

export default Head;
