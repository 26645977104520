import React from 'react';

import Hyperlink from '../../controls/Hyperlink';

import formatPermalink from '../../../utilities/formatPermalink';

import './index.scss';

const Blockquote = props => {

	const margin = props.margin && props.margin !== 'none' ? `margin-${props.margin}` : '';

	const url = props.optionalLink.linkType === 'custom' ? props.optionalLink.customUrl
		: props.optionalLink.linkType === 'page' ? (props.optionalLink.pageUrl && formatPermalink(props.optionalLink.pageUrl))
			: '';

	const Quotee = () => {
		if (props.optionalLink.show && url) {
			return (
				<Hyperlink url={url} >
					<p className="quotee"><b>{props.quotee}</b></p>
				</Hyperlink>
			)
		}
		return <p className="quotee no-link"><b>{props.quotee}</b></p>;
	}

	return (
		<div id={props.id} className={`blockquote ${margin}`}>
			<div className="blockquote-inner-wrapper">
				<blockquote className="quote" cite={url} dangerouslySetInnerHTML={{ __html: props.quote}}/>
				{props.quotee && <Quotee />}
			</div>
		</div>
	)
}

export default Blockquote;

