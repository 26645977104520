import Default from './Default';
import Home from './Home';
import Post from './Post';
import Blog from './Blog';
import Projects from './Projects';
import Contact from './Contact';
import PostJobSurvey from './PostJobSurvey';
import LostBidSurvey from './LostBidSurvey';
import NotFound from './NotFound';
import withBackgroundVideo from '../hoc/withBackgroundVideo';

const templates = {
	default: Default,
	home: Home,
	post: Post,
	blog: Blog,
	projects: Projects,
	contact: Contact,
	'post-job-survey': PostJobSurvey,
	'lost-bid-survey': LostBidSurvey,
	'not-found': NotFound
};

export default Object.keys(templates).reduce((accumulator, template) => {
	accumulator[template] = withBackgroundVideo(templates[template]);
	return accumulator;
}, {});
