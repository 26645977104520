import React, { Component } from 'react';

import './index.scss';

class Video extends Component {

	shouldComponentUpdate(nextProps) {
		if (this.props !== nextProps) {
			return true;
		}
		return false;
	}

	render() {

		const aspectRatioClass = this.props.aspectRatio || this.props.platform ? ' aspect-ratio' : '';
		const aspectRatio = this.props.aspectRatio ? this.props.aspectRatio : this.props.platform ? '56.25' : '';
		const classNames = this.props.className ? ` ${this.props.className}` : '';
		const classes = `video${classNames}${aspectRatioClass}`;

		const Contents = () => {

			if (this.props.platform) {

				const isYouTube = this.props.platform === "youtube";
				const isVimeo = this.props.platform === "vimeo";

				const autoplay = this.props.autoplay ? 'autoplay=1' : '';

				const controls = this.props.controls === 'hide' && isYouTube ? `controls=0&showinfo=0&loop=1&playlist=${this.props.src}`
					: this.props.controls === 'hide' && isVimeo ? 'background=1'
						: ''

				const muted = this.props.muted ? 'mute=1' : '';

				const params = [autoplay, controls, muted].filter(Boolean).join('&');

				const src = isYouTube ? `https://www.youtube.com/embed/${this.props.src}?${params}`
					: isVimeo ? `https://player.vimeo.com/video/${this.props.src}?${params}`
						: '';

				if (this.props.src) {
					return (
						<iframe
						className={`iframe`}
						title={this.props.source}
						width="16"
						height="9"
						frameBorder="0"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						src={src} />
					)
				}
				return null;
			}

			return (
				<video poster={this.props.poster} {...this.props.htmlVideoAttributes} type={this.props.type}>
					{this.props.src && <source src={this.props.src} />}
				</video>
			)
		}

		return (
			<div className={classes} style={aspectRatio ? { paddingBottom: `${aspectRatio}%` } : {}}>
				<Contents />
			</div>
		)
	}
}

export default Video;