import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import api from './api';
import Routes from './Routes';

import MeasureScroll from './components/utilities/MeasureScroll';
import WindowSize from './components/utilities/WindowSize';
import ModalListener from './components/modals/ModalListener';
import WatchSearchQuery from './components/utilities/WatchSearchQuery';
import BackgroundVideo from './components/elements/BackgroundVideo';
import Header from './components/layout/Header';

import './scss/app.scss';

const mapStateToProps = state => ({
	pageList: state.api.lists.pages,
});

const mapDispatchToProps = dispatch => ({
	loadGlobals: data => dispatch({ type: 'LOAD_GLOBAL_DATA', payload: data })
});

class App extends Component {

	constructor(props) {
		super(props);
		this.props.loadGlobals(api.Content.globals());
	}

	componentDidUpdate(prevProps) {
		// On route change, scroll to top
		if (this.props.location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0);
		}
	}

	render() {
		return (
			<React.Fragment>
				<WindowSize />
				<MeasureScroll />
				<Header />
				<TransitionGroup className="transition-wrap">
					<CSSTransition
						timeout={{ enter: 500, exit: 250 }}
						classNames="transition"
						key={this.props.location.pathname}>
						<Routes pages={this.props.pageList} location={this.props.location} />
					</CSSTransition>
				</TransitionGroup>
				<BackgroundVideo />
				<ModalListener />
				<WatchSearchQuery />
			</React.Fragment>
		)
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
