import React from 'react';

import EmbeddedImage from '../../elements/EmbeddedImage';
import HtmlBlock from '../../utilities/HtmlBlock';
import Parallax from '../../elements/Parallax';
import Video from '../../elements/Video';
import ToggleModal from '../../controls/ToggleModal';
import ProjectModal from '../../modals/ProjectModal';

import './index.scss';

const HeroBanner = props => {
    const margin = props.margin && props.margin !== 'none' ? ` margin-${props.margin}` : '';
    const hasIconVideoLink = props.link_icon_image && props.link_icon_type === 'video' && props.link_icon_video;
    const hasIconLink = props.link_icon_image && props.link_icon_type === 'link' && props.link_icon_link;

    const Title = () => {
        if (props.titleHtml) {
            return <HtmlBlock className="title" content={props.titleHtml} />
        }

        if (props.title) {
            return <HtmlBlock className="hero-title" content={`<h1>${props.title}</h1>`} />
        }

        return null;
    };

    return (
        <div className={`hero-banner layout-${props.media_layout}${margin}`}>
            <div className={`hero-content-wrapper align-${props.copy_alignment}`}>
                <Parallax speed={.6} className="hero-content-parallax">
                    <Title />
                    {props.content &&
                        <HtmlBlock className="hero-content" content={props.content} />
                    }
                    {hasIconVideoLink && (
                        <ToggleModal target={`link-video-${props.id}`}>
                            <EmbeddedImage image={props.link_icon_image} className="link-icon-image" />
                        </ToggleModal>
                    )}
                    {hasIconLink && (
                        <a href={props.link_icon_link.url} target={props.link_icon_link.target} rel="noopener noreferrer">
                            <EmbeddedImage image={props.link_icon_image} className="link-icon-image" />
					    </a>
                    )}
                </Parallax>
            </div>
            {hasIconVideoLink && 
                <ProjectModal modalSlug={`link-video-${props.id}`} data={{
                    acf: {
                        embeddedVideo: props.link_icon_video
                    },
                    title: {
                        rendered: props.title
                    }
                }} />
            }
            <div className="hero-media-wrapper">
                <Parallax speed={.3} className="hero-media-parallax">
                    <div className="hero-media">
                        {props.featured_image && <EmbeddedImage className="hero-image" image={props.featured_image} />}
                        {props.featured_video && 
                            <Video
                                className="hero-video"
                                htmlVideoAttributes={{ loop: true, muted: true, autoPlay: true }}
                                poster={props.featured_video_poster.url}
                                src={props.featured_video.url}
                                type="video/mp4" />
                        }
                    </div>
                </Parallax>
            </div>
        </div>
    );
};

export default HeroBanner;
