import React from 'react';

import HtmlBlock from '../../utilities/HtmlBlock';

import './index.scss';

const TitleContent = props => {

	const margin = props.margin && props.margin !== 'none' ? ` margin-${props.margin}` : '';

	return (
		<div id={props.id} className={`title-content${margin}`}>
			<div className="row">
				{props.titleHtml ? (
					<HtmlBlock className="title" content={props.titleHtml} />
				) : (
					<h3 className="title">{props.title}</h3>
				)}
				<HtmlBlock className="content" content={props.content} />
			</div>
		</div>
	)
}

export default TitleContent;
