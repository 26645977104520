import React, { Component } from 'react';
import { connect } from 'react-redux';

import './index.scss';

const mapStateToProps = state => ({
	searchParams: state.browser.searchParams
})

class ModalListener extends Component {

	constructor(props) {
		super(props);
		this.state = { isClient: false }
	}

	componentDidMount() {
		this.setState({ isClient: true });
	}

	render() {

		const { modal } = this.props.searchParams;

		if (this.state.isClient) {
			return <div className={`modal-mask${modal ? ' open' : ''}`} />
		}

		return null;
	}
}

export default connect(mapStateToProps)(ModalListener);
