import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import formSerialize from 'form-serialize';

import './index.scss';

const mapStateToProps = state => ({
	searchParams: state.browser.searchParams,
	lists: state.api.lists
})

class FilterForm extends Component {

	constructor(props) {
		super(props);
		this.formRef = React.createRef();
	}

	performFilter = () => {
		const queryString = formSerialize(this.formRef.current);
		this.props.history.push(`${this.props.location.pathname}?${queryString}`);
	}

	render() {

		const taxonomyItems = this.props.lists[this.props.taxonomy];
		const currentlySelectedTaxonomies = this.props.searchParams[this.props.taxonomy];

		const style = {
			display: this.props.searchParams.modal ? 'none' : ''
		}

		return (
			<form method="GET" action="" className={`taxonomy-filter-form${this.props.isVisible ? ' visible' : ''}`} ref={this.formRef} style={style}>
				<span className="filter-title">Filter By:</span>

				{taxonomyItems.map((taxonomyObject, i) => {

					const isChecked = Boolean(currentlySelectedTaxonomies) && currentlySelectedTaxonomies.indexOf(taxonomyObject.ID.toString()) > -1;

					return (
						<div className="checkbox" key={i}>
							<input
							id={taxonomyObject.slug}
							type="checkbox"
							name={`${this.props.taxonomy}[]`}
							value={taxonomyObject.ID}
							onChange={this.performFilter}
							checked={isChecked} />
							<label htmlFor={taxonomyObject.slug}>{taxonomyObject.title}</label>
						</div>
					)
				})}
			</form>
		)
	}
}

export default withRouter(connect(mapStateToProps)(FilterForm));