import React, { Component } from 'react';
import { connect } from 'react-redux';

import EmbeddedImage from '../../elements/EmbeddedImage';
import Parallax from '../../elements/Parallax';
import Video from '../../elements/Video';
import Arrow from '../../graphics/Arrow';

import stylizeNumber from '../../../utilities/stylizeNumber';

import './index.scss';

const mapStateToProps = state => ({
	windowWidth: state.browser.windowWidth
});

class AdvancedHeading extends Component {

	constructor(props) {
		super(props);

		this.contentRef = React.createRef();
		this.mediaRef = React.createRef();

		this.state = { minHeight: 0 }
	}

	measureHeights = () => {
		const contentHeight = this.contentRef.current.clientHeight;
		const mediaHeight = this.mediaRef.current.clientHeight;
		const minHeight = contentHeight > mediaHeight ? contentHeight : mediaHeight;
		this.setState({ minHeight });
	}

	componentDidMount() {
		this.measureHeights();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.windowWidth !== this.props.windowWidth) {
			this.measureHeights();
		}
	}

	render() {

		const data = this.props.data;

		const {
			title,
			description,
			labelNumber,
			labelText,
			mediaOrientation,
			videoSnippet,
			image,
			margin
		} = this.props.data;

		if (data) {

			const marginClass = margin && margin !== 'none' ? ` margin-${margin}` : '';

			return (
				<div id={this.props.id} className={`advanced-header left${marginClass}`} style={{ minHeight: this.state.minHeight }}>
					<div className="advanced-header-outter-wrapper">
						<div className="advanced-header-content-wrapper" ref={this.contentRef}>
							<div className="advanced-header-content">
								<Parallax speed={.3} className="advanced-header-title">
									<h1>{title}</h1>
								</Parallax>
								<hr />
								<p className="advanced-header-excerpt">
									{description}
								</p>
								<div className="advanced-header-down-arrow">
									<Arrow />
								</div>
							</div>
							<aside>
								<Parallax speed={.8} className="advanced-header-index">
									<span>{stylizeNumber(labelNumber)}</span>
								</Parallax>
								<span className="advanced-header-client">{labelText}</span>
							</aside>
						</div>
						<div className={`advanced-header-media-wrapper ${mediaOrientation === "vertical" ? "vertical" : ""}`} ref={this.mediaRef}>
							<Parallax speed={.6} className={`advanced-header-video ${mediaOrientation === "vertical" ? "advanced-header-video-vertical" : ""}`}>
								<Video
									aspectRatio={mediaOrientation === "horizontal" ? 56.25 : 56.25}
									src={videoSnippet.video ? videoSnippet.video.url : ''}
									poster={videoSnippet.poster ? videoSnippet.poster.url : ''}
									htmlVideoAttributes={{ autoPlay: true, loop: true, muted: true, playsInline: true }}
									type="video/mp4" />
							</Parallax>
							<Parallax speed={.5} className={`advanced-header-image ${mediaOrientation === "vertical" ? "advanced-header-image-vertical" : ""}`}>
								<EmbeddedImage onLoad={this.measureHeights} size={mediaOrientation === "horizontal" ? "card" : "" } image={image} />
							</Parallax>
						</div>
					</div>
				</div>
			)
		}
		return null;
	}
}

export default connect(mapStateToProps)(AdvancedHeading);
