const initialState = {
    backgroundVideo: {},
    showDefaultBackgroundVideo: true
};

export default function theme (state = initialState, action) {
    switch (action.type) {
        case 'SET_BACKGROUND_VIDEO':
            return {
                ...state,
                backgroundVideo: action.payload
            };
        case 'SET_SHOW_DEFAULT_BACKGROUND_VIDEO':
            return {
                ...state,
                showDefaultBackgroundVideo: action.payload
            };
        default:
            return state;
    }
};

export const SET_BACKGROUND_VIDEO = backgroundVideo => ({
    type: 'SET_BACKGROUND_VIDEO',
    payload: backgroundVideo
});

export const SET_SHOW_DEFAULT_BACKGROUND_VIDEO = showDefaultBackgroundVideo => ({
    type: 'SET_SHOW_DEFAULT_BACKGROUND_VIDEO',
    payload: showDefaultBackgroundVideo
});
