import React, { Component } from 'react';

import Tooltip from '../../elements/Tooltip';
import './index.scss';

class RadioGroup extends Component {
	constructor() {
		super();

		this.state = {
			options: [],
			selectedOption: ''
		}
	}

	componentDidMount() {
		let optionsArray = [];
		this.props.options.map(label => {
			let optionObject = {};
			optionObject.label = label;
			optionObject.value = label;
			optionObject.name = this.props.id;
			optionObject.id = this.props.id;
			optionObject.isSelected = false;
			optionsArray.push(optionObject)
		})

		this.setState({ options: optionsArray })
	}

	validate = () => {
		return this.state.selectedOption.length > 0;
	}

	handleClick = (changeEvent, selectedOption) => {
		let optionsCopy = [...this.state.options]
		optionsCopy.map(option => {
			if (option.label === selectedOption) {
				option.isSelected = true;
			} else {
				option.isSelected = false;
			}
		});

		this.setState({ options: optionsCopy, selectedOption })

		if (this.props.change) this.props.change(changeEvent);
	}

	errors = {
		text: 'Please select an answer'
	}

	render() {

		const Required = this.props.required
			? () => <span className="required">*</span>
			: () => { return null };

		let Error = !this.props.valid
			? () => <Tooltip className="error-message">{this.errors.text}</Tooltip>
			: () => { return null };

		const Label = this.props.label
			? () => <label className="group-label" htmlFor={this.props.id}><span>{this.props.label}<Required /></span></label>
			: () => null;

		let className = `interact radio-group-wrap`;
		className = this.props.valid ? className : className + ' error';
		className += this.props.className ? ` ${this.props.className}` : '';

		return (
			<div className={className} id={this.props.id} required={this.props.required} value={this.state.selectedOption} ref={el => this.el = el}>
				<Error />
				<Label />
				{this.state.options.length > 0 && this.state.options.map((option, index) => {
					return (
						<label className='input-radio' key={index}>
							<span className={`checkbox-wrapper${option.isSelected ? ' checked' : ''}`}>
								<input
									className='input'
									id={option.id}
									type='radio'
									name={option.id}
									checked={option.isSelected}
									value={option.value}
									onClick={(e) => this.handleClick(e, option.label)}
									readOnly />
							</span>
							{option.label}
						</label>
					)
				})}
			</div>
		);
	}
}

export default RadioGroup;