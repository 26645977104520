import React, { Component } from 'react';
import { connect } from 'react-redux';

import LoadContent from '../../utilities/LoadContent';

import templates from '../';
import NotFound from '../NotFound';
import Head from '../../elements/Head';
import Footer from '../../layout/Footer';

const mapStateToProps = state => ({
	data: state.api.data,
	headerHeight: state.browser.headerHeight
})

class LoadTemplate extends Component {

	constructor(props) {
		super(props);

		this.state = {
			hasNoData: false,

			// Slug will either come from a prop or a URL param from Router
			// Necessary because some slugs come from URL params
			slug: this.props.slug
				? this.props.slug
				: this.props.match.params.slug
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.match.params.slug !== this.props.match.params.slug) {
			this.setState({
				slug: this.props.match.params.slug
			})
		}
	}

	handleLoaded = res => {
		if (!res.length) {
			this.setState({ hasNoData: true })
		}
	}

	render() {

		const data = this.props.data[this.props.type] && this.props.data[this.props.type][this.state.slug];
		const Template = templates[this.props.template];

		const style = {
			minHeight: `calc(100vh - ${this.props.headerHeight}px)`
		}

		if (!Template || this.state.hasNoData || this.state.template === 'not-found') {
			return (
				<div className="template-wrap" style={style}>
					<NotFound />
				</div>
			)
		}

		return (
			<div className="template-wrap" style={style}>
				{data &&
					<React.Fragment>
						<Head data={data} />
						<Template data={data} slug={this.state.slug} location={this.props.location} history={this.props.history} />
						<Footer />
					</React.Fragment>
				}
				<LoadContent type={this.props.type} slug={this.state.slug} onLoad={this.handleLoaded} />
			</div>
		)
	}
}

export default connect(mapStateToProps)(LoadTemplate);
