import React from 'react';

import HtmlBlock from '../../utilities/HtmlBlock';
import Form from '../../forms/Form';
import Input from '../../forms/Input';
import RadioGroup from '../../forms/RadioGroup';
import Button from '../../controls/Button';
import Textarea from '../../forms/Textarea';
import HeaderSpacer from '../../layout/HeaderSpacer';
import PageHeader from '../../elements/PageHeader';

const API_ROOT = process.env.REACT_APP_API_URL;
const FORM_ID = process.env.REACT_APP_LOST_BID_SURVEY_FORM_ID;

const radioObject = {
	understanding: {
		options: [
			"Awesome! Exceeded expectations.",
			"Spot on. Exactly what we were seeking.",
			"Average.",
			"Underwhelming.",
			"Missed the mark.",
			"[Not Applicable to this project]"
		],
		label: "How was our concept and understanding of your story?",
		id: "understanding"
	},
	pricing: {
		options: [
			"Awesome! VERY competitive/below other bids.",
			"Good. Relatively competitive and hit our sweet spot.",
			"About what we expected/on par with other bids.",
			"A little high, but within range of our budget.",
			"Too high and uncompetitive."
		],
		label: "How was our pricing?",
		id: "pricing"
	},
	working: {
		options: [
			"Awesome! Energizing, fun, and stress-free!",
			"Good. No problems at all.",
			"Average.",
			"Underwhelming.",
			"Difficult and frustrating."
		],
		label: "How was it working with us to create this bid?",
		id: "working"
	},
	presentation: {
		options: [
			"Impressive! Exceeded expectations.",
			"Good. Easy to follow.",
			"Underwhelming.",
			"Difficult to understand.",
			"[Not Applicable to this Project]"
		],
		label: "How was the overall presentation of our proposal?",
		id: "presentation"
	},
	quick_conversation: {
		options: [
			"Yes.",
			"No."
		],
		label: "We pride ourselves on being flexible. Would you entertain one more conversation so that we can find a quote that works for you?",
		id: "quick_conversation"
	}
}


const LostJobSurvey = props => {
	return (
		<article className={`${props.slug}`}>
			<HeaderSpacer />
			<PageHeader data={props.data} featuredImage={props.data['_embedded']} />
			<div className="template-inner-wrapper">
				<div className="row">
					<div className="form-outer-wrapper">
						<Form
							method="post"
							action={`${API_ROOT}/wp-json/contact-form-7/v1/contact-forms/${FORM_ID}/feedback`}
							ajax={true}
							redirect="/thank-you">
							<Input type="text" label="Your First Name" id="first-name" required className="half" />
							<Input type="text" label="Your Last Name" id="last-name" required className="half" />
							<Input type="email" label="Your Email Address" id="email" required className="half" />
							<Input type="text" label="Name of Project" id="project" required className="half" />

							<RadioGroup { ...radioObject.understanding } required />
							<RadioGroup { ...radioObject.pricing } required />
							<RadioGroup { ...radioObject.working } required />
							<RadioGroup { ...radioObject.presentation } required />
							<RadioGroup { ...radioObject.quick_conversation } required />

							<Textarea className="small" rows={3} label="Why or why not?" required id="why" />
							<Textarea className="small" rows={3} label="We love to learn! How can we better serve you on the next RFQ?" required id="help_us_learn" />
							<Textarea className="small" rows={3} label="OPTIONAL: Would you be willing to share who you awarded the work to and why?" id="share_work" />

							<Button type="submit" >
								Send Message
							</Button>
						</Form>
					</div>
					<HtmlBlock className="content" content={props.data.content.rendered} />
				</div>
			</div>
		</article>
	)
}

export default LostJobSurvey;
