import React from 'react';
import { connect } from 'react-redux';
import { SET_BACKGROUND_VIDEO, SET_SHOW_DEFAULT_BACKGROUND_VIDEO } from '../../reducers/theme';

const mapDispatchToProps = dispatch => ({
    setBackgroundVideo: backgroundVideo => dispatch(SET_BACKGROUND_VIDEO(backgroundVideo)),
    setShowDefaultBackgroundVideo: showDefaultBackgroundVideo => dispatch(SET_SHOW_DEFAULT_BACKGROUND_VIDEO(showDefaultBackgroundVideo)),
});

const withBackgroundVideo = WrappedComponent => {
    const EnhancedComponent = props => {
        if (props.data.acf.backgroundVideo) {
            props.setBackgroundVideo({
                acf: {
                    backgroundVideo: props.data.acf.backgroundVideo,
                    backgroundVideoPoster: props.data.acf.backgroundVideoPoster
                }
            });
            props.setShowDefaultBackgroundVideo(false);
        } else {
            props.setShowDefaultBackgroundVideo(true);
        }

        return <WrappedComponent {...props} />;
    };

    return connect(null, mapDispatchToProps)(EnhancedComponent);
};

export default withBackgroundVideo;
