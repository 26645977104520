import React from 'react';
import { Switch, Route } from 'react-router-dom';

import LoadPreview from './components/templates/LoadPreview';
import LoadTemplate from './components/templates/LoadTemplate';
import Proposal from './components/templates/Proposal';

const Routes = props => {
	if (props.pages.length) {
		return (
			<Switch location={props.location}>

				<Route exact key="wp-draft" path="/:type/wp-draft" render={props => {
					return <LoadPreview {...props} />;
				}} />

				<Route exact key="post" path="/posts/:slug" render={props =>
					<LoadTemplate {...props} template="post" type="posts" />} />

				{props.pages.map((route, i) => {

					// If home, set path to empty string, = '/'
					route.path = route.slug === 'home' ? route.path = '/' : route.path;

					// If template is blank, set to default
					route.template = route.template === '' ? 'default' : route.template;

					// Default WP REST API expects /pages/ and /posts/ formatting
					// Custom post types are all singular (sigh)
					route.type = route.type === 'page'
						? 'pages' : route.type === 'post'
							? 'posts' : route.type;

					return (
						<Route exact key={i} path={`/${route.path}`} render={props =>
							<LoadTemplate key={i} {...props} template={route.template} slug={route.slug} type={route.type} />} />
					)
				})}

				<Route exact key="proposals" path="/proposal/:slug" component={Proposal} />

				<Route key="not-found" render={props =>
					<LoadTemplate {...props} template="not-found" />} />

			</Switch>
		)
	}

	return null;
}

export default Routes;
