const defaultState = {
	scrollPos: 0,
	windowWidth: 0,
	windowHeight: 0,
	location: {},
	searchParams: {},
	headerHeight: 0
}

export default (state = defaultState, action) => {

	switch (action.type) {

		case 'UPDATE_SCROLL':

			return {
				...state,
				scrollPos: action.payload
			};

		case 'SET_WINDOW_HEIGHT':

			return {
				...state,
				windowHeight: action.payload.height
			}

		case 'SET_WINDOW_WIDTH':

			return {
				...state,
				windowWidth: action.payload.width
			}

		case 'SET_LOCATION':

			return {
				...state,
				location: action.payload
			}

		case 'SET_MODAL':

			return {
				...state,
				modal: action.payload
			}

		case 'SET_SEARCH_PARAMS':
			return {
				...state,
				searchParams: action.payload
			}

		case 'SET_HEADER_HEIGHT':

			return {
				...state,
				headerHeight: action.payload
			}

		default:
		//
	}

	return state;
}
