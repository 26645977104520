import React from 'react';

import ViewHeader from '../../elements/ViewHeader';
import AnimatedPageHeader from '../../elements/AnimatedPageHeader';

const PageHeader = props => {
	return props.data.acf.useAnimatedHeader ? 
        <AnimatedPageHeader data={props.data.acf.animatedHeader} /> :
        <ViewHeader data={props.data.acf.viewHeader} featuredImage={props.featuredImage} />
}

export default PageHeader;
