import React from 'react';

import Video from '../../elements/Video';
import BlogPostLink from '../../elements/BlogPostLink';
import modalWrap from '../modalWrap';
import CloseModal from '../../controls/CloseModal';
import HeaderSpacer from '../../layout/HeaderSpacer';
import CollaborationWith from '../../elements/CollaborationWith';

import './index.scss';

const ProjectModal = (props) => {
  const data = props.data;

  if (data) {
    const videoSrc =
      data.acf.embeddedVideo.platform === 'vimeo'
        ? data.acf.embeddedVideo.vimeoId
        : data.acf.embeddedVideo.youtubeId;

    return (
      <div id={props.id} className="project-modal">
        <HeaderSpacer />
        <div className="modal-inner-wrapper">
          <div className="modal-title-wrapper">
            <h5 className="modal-title">
              {data.title.rendered}
              <BlogPostLink {...data.acf} />
            </h5>
            <CloseModal />
          </div>
          {props.isOpen && (
            <Video
              aspectRatio={
                data.acf.embeddedVideo.aspectRatio
                  ? data.acf.embeddedVideo.aspectRatio
                  : 56.25
              }
              platform={data.acf.embeddedVideo.platform}
              src={videoSrc}
              poster={data.acf.embeddedVideo.poster.url}
              autoplay={true}
            />
          )}
          {data.acf.inCollaborationWith ? (
            <CollaborationWith name={data.acf.inCollaborationWith} />
          ) : null}
        </div>
      </div>
    );
  }
  return null;
};

export default modalWrap(ProjectModal, null);
