import React from 'react';
import { Link } from 'react-router-dom';

import modalWrap from '../../../modals/modalWrap';
import HeaderSpacer from '../../HeaderSpacer';

import './index.scss';

const MobileMenuModal = props => {
	if (props.menuItems) {
		return (
			<div id={ props.id } className="mobile-menu-modal">
				<HeaderSpacer />
				<nav>
					{props.menuItems.map(item => <h2 key={item.ID}><Link to={item.url}>{item.title}</Link></h2>)}
				</nav>
			</div>
		)
	}
	return null;
}

export default modalWrap(MobileMenuModal, 'mobile-menu');
