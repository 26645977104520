import React from 'react';
import CategoriesList from '../../elements/CategoriesList';
import AdvancedHeading from '../../blocks/AdvancedHeading';
import formatDate from '../../../utilities/formatDate';

import './index.scss';

const PostHeader = props => {
    const {
        date,
        categoriesData,
        title,
        acf
    } = props;

    return (
        acf.useAdvancedHeading ? (
            <AdvancedHeading data={acf.advancedHeading} />
        ) : (
            <div className="header-content">
                <div className="header-meta">
                    <span className="header-date">{formatDate(date)}</span>
                    <CategoriesList data={categoriesData} />
                </div>
                <h1 className="header-title">{ title }</h1>
                <hr className="header-delimiter" />
            </div>
        )
    );
}

export default PostHeader;
