import React from 'react';

import Project from './Project';
import Post from './Post';

import './index.scss';

const types = {
	'project': Project,
	'post': Post
}

const Card = props => {

	const CardType = types[props.type];

	if (CardType) {
		return <CardType id={`Card-${props.index}`} className={`card type-${props.type}`} data={props.data} index={props.index} />
	}
	return null;
}

export default Card;
