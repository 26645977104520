import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'qs';
import Cookies from 'universal-cookie';

import Authenticated from './Authenticated';
import Unauthenticated from './Unauthenticated';
import HeaderSpacer from '../../layout/HeaderSpacer';

import api from '../../../api';

import './index.scss';

const cookies = new Cookies();
class Proposal extends Component {

	constructor(props) {
		super(props);

		const cookie = cookies.get(`${this.props.match.params.slug}-auth`);

		this.state = {
			isMounted: false,
			params: this.getParams(),
			authenticated: cookie ? true : false,
			data: null,
			error: false,
			password: ''
		}
	}

	getParams = () => queryString.parse(
		this.props.location.search,
		{ ignoreQueryPrefix: true }
	)

	postFormData = (password, callback) => {
		api.requests.post(`${process.env.REACT_APP_API_URL}/wp-json/react-wp-rest/proposal/${this.props.match.params.slug}`, { password }).then(
			res => {

				const expires = new Date();
				expires.setMonth(expires.getMonth() + 1);

				cookies.set(`${this.props.match.params.slug}-auth`, password, { path: `/proposal/${this.props.match.params.slug}`, expires });

				this.setState({
					authenticated: true,
					data: res
				})
			},
			err => {
				this.setState({
					authenticated: false,
					error: true
				})
			}
		)
	}

	handleFormSubmit = e => {
		e.preventDefault();
		this.postFormData(this.state.password);
	}

	handlePasswordChange = e => {
		this.setState({
			password: e.target.value
		})
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location.search !== this.props.location.search) {
			this.setState({
				params: this.getParams()
			})
		}
	}

	componentDidMount() {

		const cookie = cookies.get(`${this.props.match.params.slug}-auth`);

		if (cookie) {
			this.postFormData(cookie)
		}

		this.setState({
			isMounted: true
		})
	}

	render() {

		const data = this.state.data;

		return (
			<article className={`template-proposal ${this.state.authenticated ? 'authenticated' : 'unauthenticated'}`}>
				<HeaderSpacer />
				<div className="template-wrap" style={{ minHeight: `calc(100vh - ${this.props.headerHeight}px)` }}>
					{(this.state.authenticated && this.state.isMounted && data) &&
						<Authenticated data={data} featuredImage={this.state.data['_embedded']} />
					}
					{(!this.state.authenticated && this.state.isMounted) &&
						<Unauthenticated handleFormSubmit={this.handleFormSubmit} onChange={this.handlePasswordChange} error={this.state.error} />
					}
				</div>
			</article>
		)
	}
}

export default withRouter(Proposal);
