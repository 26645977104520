import React from 'react';
import ReactTyped from 'react-typed';

import HtmlBlock from '../../utilities/HtmlBlock';
import EmbeddedImage from '../../elements/EmbeddedImage';
import Video from '../../elements/Video';
import Parallax from '../../elements/Parallax';

import './index.scss';

const AnimatedPageHeader = props => {

	const data = props.data;

    const getFeaturedMedia = () => {
        if (data.featuredImage) {
            return (
                <EmbeddedImage className="header-image" image={data.featuredImage} />
            );
        }

        let videoSrc = null;
        let platform = null;
        const videoPoster = data.featuredVideoPoster ? data.featuredVideoPoster.url : null;
        if (data.featuredVideoFile) {
            videoSrc = data.featuredVideoFile.url;
        } else if (data.featuredVideoYouTube) {
            videoSrc = data.featuredVideoYouTube;
            platform = 'youtube';
        } else if (data.featuredVideoVimeo) {
            videoSrc = data.featuredVideoVimeo;
            platform = 'vimeo';
        }

        if (videoSrc) {
            return (
                <Video
                    className="header-video"
                    htmlVideoAttributes={{ loop: true, muted: true, autoPlay: true }}
                    poster={videoPoster}
                    src={videoSrc}
                    platform={platform}
                    loop
                    muted
                    autoplay
                    controls="hide"
                    type="video/mp4" />
            );
        }

        return null;
    };

	if (data) {
		return (
            <div className="animated-page-header-wrapper">
                {data.mediaWatermarkImage &&
                    <Parallax speed={.5} className="header-watermark-wrapper">
                        <EmbeddedImage className="header-watermark" image={data.mediaWatermarkImage} />
                    </Parallax>
                }
                <header className="animated-page-header">
                    <Parallax speed={.3} className="header-media-wrapper">
                        {getFeaturedMedia()}
                    </Parallax>
                    <Parallax speed={.5} className="header-content-wrapper">
                        <div className="header-title">
                            <h1>
                                <ReactTyped
                                    strings={data.headingPhrases.map(phrase => phrase.phrase)}
                                    typeSpeed={60}
                                    backSpeed={30}
                                    backDelay={2000}
                                    showCursor
                                    loop
                                />
                            </h1>
                        </div>
                        {data.content &&
                            <HtmlBlock className="header-content" content={data.content} />
                        }
                    </Parallax>
                </header>
            </div>
		)
	}
	return null;
}

export default AnimatedPageHeader;
