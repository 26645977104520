import React, { Component } from 'react';
import { connect } from 'react-redux';

import Card from '../../elements/Card';
import PageHeader from '../../elements/PageHeader';
import CallToAction from '../../elements/CallToAction';
import LoadContent from '../../utilities/LoadContent';
import HeaderSpacer from '../../layout/HeaderSpacer';

import './index.scss';

const mapStateToProps = state => ({
	projects: state.api.data.projects
})
class Home extends Component {
	constructor(props) {
		super(props);
		this.state = { projects: [] }
	}

	handleLoaded = projects => {
		this.setState({ projects });
	}

	render() {
		return (
			<article className={ `${this.props.slug} template-home` }>
				<HeaderSpacer />
				<PageHeader data={this.props.data} featuredImage={this.props.data['_embedded']} />
				<div className="home-featured-projects">
					{this.state.projects.map((project, index) => {
						const projectObject = this.props.projects[project.slug];
						if (projectObject) {
							return (
								<Card type="project" data={projectObject} key={index} index={index} />
							)
						}
						return null;
					})}
				</div>
				{this.props.data && this.props.data.acf.featuredProjects && this.props.data.acf.featuredProjects.length > 0 &&
					<LoadContent type="projects" ids={this.props.data.acf.featuredProjects.map(project => project.ID)} onLoad={this.handleLoaded} />
				}
				<CallToAction data={this.props.data.acf.CallToAction} />
			</article>
		)
	}
}

export default connect(mapStateToProps)(Home);
