import React from "react";

import modalWrap from "../modalWrap";
import CloseModal from "../../controls/CloseModal";
import HeaderSpacer from "../../layout/HeaderSpacer";

import "./index.scss";

const ImageGridModal = (props) => {
    const image = props.image;

    if (image) {
        return (
            <div id={props.id} className="image-grid-modal">
                <HeaderSpacer />
                <div className="modal-inner-wrapper">
                    <div className="modal-title-wrapper">
                        <h5 className="modal-title">
                            {image.title || image.alt}
                        </h5>
                        <CloseModal />
                    </div>
                    {props.isOpen && (
                        <img src={image.url} alt={image.alt} />
                    )}
                </div>
            </div>
        );
    }

    return null;
};

export default modalWrap(ImageGridModal, null);
