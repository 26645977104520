import React from 'react';

import Facebook from '../../graphics/Facebook';
import Instagram from '../../graphics/Instagram';
import LinkedIn from '../../graphics/LinkedIn';
import Twitter from '../../graphics/Twitter';
import Vimeo from '../../graphics/Vimeo';
import YouTube from '../../graphics/YouTube';

import './index.scss';

const Icons = {
	'facebook': Facebook,
	'instagram': Instagram,
	'linkedin': LinkedIn,
	'twitter': Twitter,
	'vimeo': Vimeo,
	'youtube': YouTube
}

const SocialMedia = props => {
	const Icon = Icons[props.icon];
	if (Icon) {
		if (props.url) {
			return (
				<a className="social-media" href={ props.url } target="_blank" rel="noopener noreferrer">
					<Icon />
				</a>
			)
		}
		return (
			<div className="social-media">
				<Icon />
			</div>
		)
	}
	return null;
}

export default SocialMedia;