import { Component } from 'react';
import { connect } from 'react-redux';

import api from '../../../api';

import sanitizePostType from '../../../utilities/sanitizePostType';

const mapStateToProps = state => ({
	data: state.api.data
});

const mapDispatchToProps = dispatch => ({
	load: data => dispatch({ type: 'LOAD_DATA', payload: data }),
	loadBySlug: data => dispatch({ type: 'LOAD_DATA_BY_SLUG', payload: data })
});

class LoadContent extends Component {
	constructor(props) {
		super(props);
		this.state = { hasLoaded: false }
		this.load();
	}

	dispatchData = res => {
		const slugs = [];
		const isEnveloped = this.props.query && this.props.query._envelope;
		const data = isEnveloped ? res.body : res;

		// WP sends back 200, structured strangely
		if (data.data && data.data.status === 400) {
			console.error(data.message);
		} else {
			data.map((item) => {
				slugs.push({
					type: item.type,
					slug: item.slug
				});

				return this.props.loadBySlug({
					type: sanitizePostType(item.type),
					slug: item.slug,
					data: [item]
				})
			});

			const loadedData = isEnveloped ? {
				slugs,
				headers: res.headers
			} : slugs;

			this.props.onLoad && this.props.onLoad(loadedData);
		}

		this.setState({ hasLoaded: true });
	}

	handleError = error => {
		console.warn(error);
		this.setState({ hasLoaded: true });
	}

	load = () => {
		if (this.props.slug) {
			if (!this.props.data[this.props.type][this.props.slug]) {
				// Load page content from API by slug
				api.Content.dataBySlug(this.props.type, this.props.slug).then(
					res => {
						this.props.loadBySlug({
							type: this.props.type,
							slug: this.props.slug,
							data: res
						})
						this.props.onLoad && this.props.onLoad(res);
					},
					error => this.handleError(error)
				);
			}
		} else if (this.props.ids && this.props.ids.length > 0 && !this.state.hasLoaded) {
			api.Content.dataByIds(this.props.ids, sanitizePostType(this.props.type)).then(
				res => this.dispatchData(res),
				error => this.handleError(error)
			);
		} else if (this.props.type && !this.state.hasLoaded) {
			api.Content.data(this.props.type, this.props.query).then(
				res => this.dispatchData(res),
				error => this.handleError(error)
			)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if ((prevProps.ids && prevProps.ids.toString()) !== (this.props.ids && this.props.ids.toString())
			|| JSON.stringify(prevProps.query) !== JSON.stringify(this.props.query)) {
			this.setState({
				hasLoaded: false
			}, () => {
				this.load();
			})
		}
	}

	render() {
		return null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadContent);
