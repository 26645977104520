import React from 'react';

import HtmlBlock from '../../utilities/HtmlBlock';
import Button from '../../controls/Button';

import './index.scss';

const Content = props => {

	const margin = props.margin && props.margin !== 'none' ? ` margin-${props.margin}` : '';

	return (
		<div id={props.id} className={`content-block${margin}`}>
			<div className="row">
				{props.row &&
					props.row.map((column, i) => {
						const url = column.optionalButton.linkType === 'page' ? column.optionalButton.pageUrl : column.optionalButton.customUrl;
						const hasButton = column.optionalButton.show;
						return (
							<div className={`column ${column.width}${hasButton ? ' has-button' : ''}`} key={i}>
								<HtmlBlock content={column.column} />
								{hasButton && <Button url={url} label={column.optionalButton.label} type={column.optionalButton.type} color="gray" />}
							</div>
						)
					})
				}
			</div>
		</div>
	)
}

export default Content;
