import React, { Component } from 'react';
import ToggleModal from '../../controls/ToggleModal';
import EmbeddedImage from '../EmbeddedImage';
import Video from '../Video';

import './index.scss';

class VideoHover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };
  }

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  render() {
    const { projectObject } = this.props;
    const { isHovered } = this.state;

    const hasVideo = projectObject.acf.videoSnippet.video && projectObject.acf.videoSnippet.video.url;

    return (
      <ToggleModal
        target={`project-${projectObject.slug}`}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {hasVideo && isHovered ? (
          <Video
            className="video-hover"
            aspectRatio={
              projectObject.acf.videoSnippet.aspectRatio
                ? projectObject.acf.videoSnippet.aspectRatio
                : 56.25
            }
            src={
              projectObject.acf.videoSnippet.video
                ? projectObject.acf.videoSnippet.video.url
                : ""
            }
            poster={
              projectObject.acf.videoSnippet.poster
                ? projectObject.acf.videoSnippet.poster.url
                : ""
            }
            htmlVideoAttributes={{
              autoPlay: true,
              loop: true,
              muted: true,
              playsInline: true,
            }}
            type="video/mp4"
          />
        ) : (
          <EmbeddedImage
            image={projectObject["_embedded"]}
            size="card-large"
          />
        )}
        <span className="toggle-modal-hover">Watch</span>
      </ToggleModal>
    );
  }
}

export default VideoHover;
