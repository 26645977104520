import React, { Component } from 'react';
import { connect } from 'react-redux';

import EmbeddedImage from '../../../elements/EmbeddedImage';
import Button from '../../../controls/Button';
import CategoriesList from '../../CategoriesList';
import Parallax from '../../../elements/Parallax';
import Hyperlink from '../../../controls/Hyperlink';

import stylizeNumber from '../../../../utilities/stylizeNumber';

import './index.scss';

const mapStateToProps = state => ({
	windowWidth: state.browser.windowWidth
})

class PostCard extends Component {

	constructor(props) {
		super(props);

		this.contentRef = React.createRef();
		this.imageRef = React.createRef();
		this.imageLoaded = false;

		this.state = {
			contentHeight: 0,
			imageHeight: 0
		}
	}

	measureContentHeight = () => {
		const contentHeight = this.contentRef.current.clientHeight;
		this.setState({ contentHeight });
	}

	measureImageHeight = () => {
		const imageHeight = this.imageRef.current.clientHeight;
		this.setState({ imageHeight })
	}

	handleLoaded = () => {
		if (!this.imageLoaded) {
			this.measureImageHeight();
			this.imageLoaded = true;
		}
	}

	componentDidMount() {
		this.measureContentHeight();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.windowWidth !== this.props.windowWidth) {
			this.measureContentHeight();
			this.measureImageHeight();
		}
	}

	render() {

		const data = this.props.data;
		const minHeight = this.state.contentHeight > this.state.imageHeight ? this.state.contentHeight : this.state.imageHeight;

		if (data) {

			const postUrl = `/posts/${data.slug}`;
			const speedOffset = this.props.index / 10;

			return (
				<Parallax id={this.props.id} className={this.props.className} speed={1 * speedOffset}>
					<header className="card-header" style={{ minHeight }}>
						<div className="card-header-content" ref={this.contentRef}>
							{this.props.index.toString() &&
								<Parallax speed={1} className="card-index">
									<span>{stylizeNumber(this.props.index + 1)}</span>
								</Parallax>
							}
							<div className="card-categories">
								<CategoriesList data={data['_embedded']} />
							</div>
							<Parallax speed={.2} className="card-title">
								<Hyperlink url={postUrl}>
									<h3>{data.title.rendered}</h3>
								</Hyperlink>
							</Parallax>
						</div>
						<Parallax speed={.4} className="card-image">
							<Hyperlink url={postUrl}>
								<EmbeddedImage ref={this.imageRef} size="card" image={data['_embedded']} onLoad={this.handleLoaded} />
							</Hyperlink>
						</Parallax>
					</header>
					<div className="card-footer">
						<hr />
						<p className="card-excerpt">{data.acf.metaDescription}</p>
						<Button type="text" label="Read More" url={postUrl} />
					</div>
				</Parallax>
			)
		}
		return null;
	}
}

export default connect(mapStateToProps)(PostCard);