import { Component } from 'react';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';

const mapStateToProps = state => ({
	width: state.browser.windowWidth,
	height: state.browser.windowHeight
})

const mapDispatchToProps = dispatch => ({
	setWindowHeight: (size) => dispatch({ type: 'SET_WINDOW_HEIGHT', payload: size }),
	setWindowWidth: (size) => dispatch({ type: 'SET_WINDOW_WIDTH', payload: size })
})

class WindowSize extends Component {

	setWindowHeight = () => {
		this.props.setWindowHeight({
			height: window.innerHeight
		})
	}

	setWindowWidth = () => {
		this.props.setWindowWidth({
			width: document.body.clientWidth
		})
	}

	onResize = () => {

		// Only resize the height on screens larger than mobile
		// to avoid toolbars hiding and orientation change
		if (document.body.clientWidth > 450) {
			this.setWindowHeight();
		}

		// But resize the width on all widow resize events
		this.setWindowWidth();
	}

	componentDidMount() {
		window.addEventListener('resize', throttle(this.onResize, 400));

		window.addEventListener('orientationchange', () => {
			setTimeout(() => {
				this.onResize();
			}, 500);
		})

		this.setWindowHeight();
		this.setWindowWidth();
	}

	render() {
		return false;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowSize);
