const cardSizes = [
    'card-large',
    'card',
    'medium',
];

export const getCardSizeURL = (image) => {
    if (!image) {
        return '';
    }

    if (image.sizes) {
        for (let i = 0; i < cardSizes.length; i++) {
            if (image.sizes[cardSizes[i]]) {
                return image.sizes[cardSizes[i]];
            }
        }
    }

    return image.url;
};
