import React from 'react';
import { connect } from 'react-redux';

import HtmlBlock from '../../utilities/HtmlBlock';
import Button from '../../controls/Button';
import EmbeddedImage from '../../elements/EmbeddedImage';
import Video from '../../elements/Video';

import smoothScroll from '../../../utilities/smoothScroll';

import './index.scss';

const mapStateToProps = state => ({
	headerHeight: state.browser.headerHeight
})

const ViewHeader = props => {

	const data = props.data;

	if (data) {

		const type = data.type ? ` type-${data.type}` : '';
		const hasVideo = data.showBackgroundVideo ? ' has-backgrnd-video' : '';

		const isFullscreeen = data.type === 'fullscreen';
		const isContentImage = data.type === 'image-content';

		let buttonUrl = '';
		if (data.optionalButton) {
			if (data.optionalButton.linkType === 'page') {
				buttonUrl = data.optionalButton.pageUrl;
			} else if (data.optionalButton.linkType === 'custom') {
				buttonUrl = data.optionalButton.customUrl;
			}
		}

		let style = {};

		if (isFullscreeen) {
			style = {
				minHeight: `calc(100vh - ${props.headerHeight}px)`,
				paddingBottom: props.headerHeight
			}
		}

		return (
			<header className={`view-header${type}${hasVideo}`} style={style}>
				{isContentImage &&
					<EmbeddedImage className="header-image" image={props.featuredImage} />
				}
				{data.showBackgroundVideo &&
					<Video
					className="header-video"
					htmlVideoAttributes={{ loop: true, muted: true, autoPlay: true }}
					poster={data.backgroundVideoGroup.backgroundVideoPoster.url}
					src={data.backgroundVideoGroup.backgroundVideo.url}
					type="video/mp4" />
				}
				<div className="header-content-wrapper">
					{data.title &&
						<HtmlBlock className="header-title" content={`<h1>${data.title}</h1>`} />
					}
					{data.content &&
						<HtmlBlock className="header-content" content={data.content} />
					}
					{data.optionalButton && data.optionalButton.show &&
						<Button label={data.optionalButton.label} url={buttonUrl} type={data.optionalButton.type} />
					}
					{isFullscreeen && data.scrollTo &&
						<button className="header-scroll-to" onClick={() => { smoothScroll.scrollTo(data.scrollTo) }}>Scroll</button>
					}
				</div>
			</header>
		)
	}
	return null;
}

export default connect(mapStateToProps)(ViewHeader);