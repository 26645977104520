import React, { Component } from 'react';
import queryString from 'query-string';
import { withRouter, Link } from 'react-router-dom';

import './index.scss';

class Paginator extends Component {

	constructor(props) {
		super(props);

		this.state = {
			params: this.getParams()
		}
	}

	getParams = () => {
		return queryString.parse(
			this.props.location.search,
			{ ignoreQueryPrefix: true }
		)
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location !== this.props.location) {
			this.setState({
				params: this.getParams()
			})
		}
	}

	handleClick() {
		window.scrollTo(0, 0);
	}

	render() {

		const pages = Math.ceil(this.props.total / this.props.per);
		const current = this.props.current ? Number(this.props.current) : 1;

		if (pages > 1) {
			return (
				<div className="paginator">
					<ul>
						{[...Array(pages)].map((_, i) => {

							const pageNumber = i + 1;

							if (pages > 7) {
								// If beyond the third page, render an ellipsis in place of all page numbers
								// that are between the FIRST page and ONE LESS than the CURRENT page
								const minThreshold = current === pages ? current - 2 : current - 1;
								if (current >= 3 && pageNumber !== 1 && pageNumber < minThreshold) {
									if (pageNumber === 2) { return <li key={i} className="paginator-ellipsis">...</li> }
									return null;
								}
								// If before third from the last page, render an ellipsis in place of all page numbers
								// that are between the LAST page and ONE MORE than the CURRENT page
								const maxThreshold = current === 1 ? current + 2 : current + 1;
								if (current <= pages - 3 && pageNumber !== pages && pageNumber > maxThreshold) {
									if (pageNumber === pages - 1) { return <li key={i} className="paginator-ellipsis">...</li> }
									return null;
								}
							}

							// If the page number is the current/active page
							if (current === pageNumber) {
								return (
									<li key={i}>
										<span key={i}>{pageNumber}</span>
									</li>
								)
							}

							const newParams = { ...this.state.params, page: pageNumber };
							const path = `${this.props.location.pathname}?${queryString.stringify(newParams)}`;

							return (
								<li key={i}>
									<Link key={i} to={path} onClick={this.handleClick}>
										{pageNumber}
									</Link>
								</li>
							)
						})}
					</ul>
				</div>
			)
		}

		return null;
	}
}

export default withRouter(Paginator);
