import React from 'react';
import Button from '../../controls/Button';
import './index.scss';

const BlogPostLink = (props) => {
    const {
        blogPostLink,
        blogPostLinkText
    } = props;

    if (blogPostLink) {
        const linkText = blogPostLinkText ? blogPostLinkText : 'Check out the Case Study for this project';
        return (
            <span className="blog-post-link-container">
                <br class="mobile-break" />
                <span className="link-separator">|</span>
                <a href={blogPostLink} className="blog-post-link" title={linkText}>
					{linkText}
                    <Button className="arrow-button" arrow={true} />
				</a>
            </span>
        );
    }

    return null;
}

export default BlogPostLink;
