import React, { Component } from 'react';
import { connect } from 'react-redux';

import './index.scss';

const mapStateToProps = state => ({
	headerHeight: state.browser.headerHeight
})

class HeaderSpacer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMounted: false
		}
	}

	componentDidMount() {
		this.setState({ isMounted: true })
	}

	render() {
		return (
			<div className="header-spacer" style={{ height: this.state.isMounted ? `${this.props.headerHeight}px` : '' }} />
		)
	}
}

export default connect(mapStateToProps)(HeaderSpacer);
