import React from 'react';

import FlexibleContent from '../../utilities/FlexibleContent';
import HeaderSpacer from '../../layout/HeaderSpacer';
import PageHeader from '../../elements/PageHeader';

const Default = props => {
	return (
		<article className={ `${props.slug} template-default` }>
			<HeaderSpacer />
			<PageHeader data={props.data} featuredImage={props.data['_embedded']} />
			<FlexibleContent content={props.data.acf.blocks} />
		</article>
	)
}

export default Default;
