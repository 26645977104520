import React from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import './index.scss';

const CloseModal = props => {
	const createString = () => {
		const queryObject = queryString.parse(
			props.location.search,
			{ ignoreQueryPrefix: true }
		)
		const newParams = { ...queryObject };
		delete newParams.modal;
		return queryString.stringify(newParams);
	}

	const handleClick = () => {
		props.history.push({ search: createString() });
	}

	return (
		<span onClick={handleClick} className="close-modal">Close</span>
	)
}

export default withRouter(CloseModal);