import React from 'react';

import './index.scss';

const ImageBlock = props => {

	const margin = props.margin && props.margin !== 'none' ? ` margin-${props.margin}` : '';
	const size = props.size ? ` size-${props.size}` : '';

	return (
		<div id={ props.id } className={ `image-block${margin}${size}` }>
			<div className="image-block-wrapper">
				<img src={props.image.url} alt={props.image.alt} />
				{props.caption &&
					<span className="image-caption" dangerouslySetInnerHTML={ {__html: props.caption} }/>
				}
			</div>
		</div>
	)
}

export default ImageBlock;