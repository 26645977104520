import React from 'react';

import Video from '../../elements/Video';

import './index.scss';

const VideoBlock = props => {

	const margin = props.margin && props.margin !== 'none' ? ` margin-${props.margin}` : '';
	const platform = props.embeddedVideo.platform;
	const videoSrc = platform === 'vimeo' ? props.embeddedVideo.vimeoId : props.embeddedVideo.youtubeId;
	const size = props.size ? ` size-${props.size}` : '';

	return (
		<div id={ props.id } className={ `video-block${margin}${size}` }>
			<div className="video-block-wrapper">
				<Video
				aspectRatio={props.embeddedVideo.aspectRatio ? props.embeddedVideo.aspectRatio : 56.25}
				platform={platform}
				src={videoSrc}
				poster={props.embeddedVideo.poster.url} />
				{props.caption &&
					<span className="video-caption" dangerouslySetInnerHTML={ {__html: props.caption} }/>
				}
			</div>
		</div>
	)
}

export default VideoBlock;