import React, { Component } from 'react';
import { connect } from 'react-redux';

import LoadContent from '../../utilities/LoadContent';
import ProjectModal from '../../modals/ProjectModal';
import HeaderSpacer from '../../layout/HeaderSpacer';
import VideoHover from '../../elements/VideoHover';

import './index.scss';

const mapStateToProps = state => ({
	projects: state.api.data.projects
})

class Projects extends Component {
	constructor(props) {
		super(props);
		this.state = {
			projects: []
		}
	}

	handleLoaded = projects => {
		this.setState({ projects })
	}

	render() {
		return (
			<article className={ `${this.props.slug} template-projects` }>
				<HeaderSpacer />
				<h1 className="template-title">{this.props.data.acf.alternateHeaderTitle}</h1>
				<div className="projects-archive">
					<div className="row projects-archive-row">
						{this.state.projects.length > 0 && this.state.projects.map((project, index) => {
							if (this.props.projects[project.slug]) {
								const projectObject = this.props.projects[project.slug];
								return (
									<React.Fragment key={index}>
										<VideoHover projectObject={projectObject} />
										<ProjectModal modalSlug={`project-${projectObject.slug}`} data={projectObject} />
									</React.Fragment>
								)
							}
							return null;
						})}
					</div>
				</div>
				<LoadContent type="projects" onLoad={this.handleLoaded} query={{ per_page: 100 }} />
			</article>
		)
	}
}

export default connect(mapStateToProps)(Projects);
