import React from 'react';

import Button from '../../controls/Button';

import './index.scss';

const CallToAction = props => {

	const data = props.data;

	if (data) {

		const url = data.optionalButton.linkType === 'page' ? data.optionalButton.pageUrl : data.optionalButton.customUrl;

		return (
			<div className="call-to-action">
				<h2>{data.title}</h2>
				<Button label={data.optionalButton.label} url={url} type="text"/>
			</div>
		)
	}
	return null;
}

export default CallToAction;