import React from 'react';
import { connect } from 'react-redux';

import './index.scss';

const mapStateToProps = state => ({
	proposal: state.api.data['static-content'].proposal,
	headerHeight: state.browser.headerHeight
})

const Unauthenticated = props => {
	return (
		<div className="proposal-outer-wrapper" style={ { minHeight: `calc(100vh - ${props.headerHeight * 2}px)`, marginBottom: `${props.headerHeight}px` } }>
			<div className="proposal-inner-wrapper">
				<h1>Password Required</h1>
				<form onSubmit={props.handleFormSubmit}>
					<div className="interact">
						{props.error &&
							<div className="status error open">
								Sorry, that password is incorrect.
							</div>
						}
						<label>Please enter the password</label>
						<input type="password" name="password" onChange={props.onChange} />
					</div>
				</form>
			</div>
		</div>
	)
}

export default connect(mapStateToProps)(Unauthenticated);
